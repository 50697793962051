import React from 'react'
import { Table } from 'react-bootstrap'
import { capitalizeFirstLetter, handleCodeableConcept } from '../../../../utils/helpers'
import { Link } from 'react-router-dom'
import { ProcedureBundle } from '../../../../types/FHIRTypes/Bundle'
import { ProcedureEntry } from '../../../../types/FHIRTypes/Entry'
import { Procedure } from '../../../../types/FHIRTypes/Procedure'

interface ProcedureListProps {
    procedureData: ProcedureBundle;
    isFetching: boolean;
}

const ProcedureList: React.FC<ProcedureListProps> = ({ procedureData, isFetching }) => {
    const procedures: Procedure[] = procedureData && procedureData.entry ? procedureData.entry.map((procedure: ProcedureEntry) => procedure.resource) : []

    const getProcedurePerformedDate = (procedure: Procedure) => {
        if (procedure.performedDateTime) {
            return new Date(procedure.performedDateTime).toLocaleDateString()
        }

        if (procedure.performedPeriod) {
            const startDate = new Date(procedure.performedPeriod.start).toLocaleDateString()
            const endDate = procedure.performedPeriod.end ? new Date(procedure.performedPeriod.end).toLocaleDateString() : ''
            return endDate ? `${startDate} - ${endDate}` : startDate
        }

        if (procedure.performedString) {
            return procedure.performedString
        }

        if (procedure.performedAge) {
            return `${procedure.performedAge}`
        }

        if (procedure.performedRange) {
            return `${procedure.performedRange.low.value} - ${procedure.performedRange.high.value}`
        }

        return ''
    }

    return (
        <Table size='sm' hover>
            <thead>
                <tr>
                    <th className='d-table-cell'>Code</th>
                    <th className='d-none d-lg-table-cell'>Status</th>
                    <th className='d-none d-lg-table-cell'>Performed</th>
                    <th className='d-none d-lg-table-cell'>View Detail</th>
                </tr>
            </thead>
            <tbody>
                {
                    isFetching ? (
                        <tr>
                            <td colSpan={4} rowSpan={15} className='d-flex'>
                                Loading Member Procedures...
                            </td>
                        </tr>
                    ) : procedures?.length === 0 ? (
                        <tr>
                            <td colSpan={4} rowSpan={15} className='text-center'>
                                No Procedures found for this member.
                            </td>
                        </tr>
                    ) : (
                        <>
                            {procedures.map((procedure) => (
                                <tr key={procedure.id}>
                                    <td className='d-table-cell'><Link to={`/patient/Procedures/${procedure.id}`}>{handleCodeableConcept(procedure.code)}</Link></td>
                                    <td className='d-none d-lg-table-cell'>{capitalizeFirstLetter(procedure.status)}</td>
                                    <td className='d-none d-lg-table-cell'>{getProcedurePerformedDate(procedure)}</td>
                                    <td className='d-none d-lg-table-cell'><Link to={`/patient/Procedures/${procedure.id}`}>View Detail</Link></td>
                                </tr>
                            ))}
                        </>
                    )
                }
            </tbody>
        </Table>
    )
}

export default ProcedureList