import React, { FC } from 'react'
import ConditionList from './ConditionsList'
import ConditionDetail from './ConditionsDetail'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface Props {
    conditionData: any;
    patientData: any;
    conditionDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
}



const Conditions: FC<Props> = ({ conditionData, patientData, conditionDetailId, page, isFetching }: Props) => {
    return (
        <div>
            <h3>Conditions</h3>
            <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
            {
                conditionDetailId && page === 'Conditions' ? (
                    <ConditionDetail conditionData={conditionData} isFetching={isFetching} conditionDetailId={conditionDetailId} />
                ) : <ConditionList conditionData={conditionData} patientData={patientData} isFetching={isFetching} />
            }
        </div>
    )
}

export default Conditions