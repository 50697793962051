import React, { FC } from 'react'
import { useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildCoveragePeriod, buildCoverageMemberID, buildCoverageStatus, buildCoverageIdentifier, buildCoverageSubId, buildCoveragePayer, buildCoverageBeneficiary, buildCoverageRelationship, sortCoveragesByStartDate } from '../../../utils/fhirUtils/coverage'
import { buildPatientId, buildPatientName } from '../../../utils/fhirUtils/patient'
import { capitalizeFirstLetter, formatDateInTimeZone } from '../../../utils/helpers'
import useFhirResource from '../../../hooks/admin/useFhirResource'

interface Props {
  coverageData: any;
  patientData: any;
  isFetching: boolean;
}

const Coverage: FC<Props> = ({ coverageData, patientData, isFetching }: Props) => {
  const patientCoverages = coverageData && coverageData.entry ? coverageData.entry.map((coverage: any) => coverage.resource) : []

  // org stuff
  const organization =
    patientCoverages[0]?.payor && patientCoverages[0]?.payor[0]
      ? patientCoverages[0]?.payor[0]?.reference
      : 'no payor'
  const trimmedOrg = organization?.split('/')[1]
  const { data: orgData, refetch: getOrg, isStale: orgsIsStale, isFetching: fetchingOrgs } = useFhirResource(`${trimmedOrg}`, 'Organization')

  useEffect(() => {
    if (trimmedOrg) {
      getOrg()
    }
  }, [trimmedOrg])

  const getClass = (classType: any) => {
    return classType.coding ? classType.coding[0].code : classType.text
  }

  return (
    <>
      <h3>Coverage</h3>
      <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
      {
        isFetching ? (
          <Row>
            <Col className='d-flex'>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span style={{ marginLeft: '10px' }}>Loading Member Coverage...</span>

            </Col>
          </Row>
        ) : (patientCoverages.length > 0) ? (
          sortCoveragesByStartDate(patientCoverages).map((coverage: any) => (
            <dl className='dataContainer' key={coverage.id}>
              <Row>

                {/* <Col sm={3}>
                  <dt>Coverage Identifier</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageIdentifier(coverage)}</dd>
                </Col> */}
                <Col sm={3}>
                  <dt>Coverage Status</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageStatus(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Subscriber ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageSubId(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Member ID</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageMemberID(coverage)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Relationship</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoverageRelationship(coverageData)}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Coverage Start</dt>
                </Col>
                <Col sm={9}>
                  <dd>{formatDateInTimeZone(buildCoveragePeriod(coverage, 'start'), 'MM/dd/yyyy', 'UTC')}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Coverage End</dt>
                </Col>
                <Col sm={9}>
                  <dd>{coverage && buildCoveragePeriod(coverage, 'end') ? formatDateInTimeZone(buildCoveragePeriod(coverage, 'end'), 'MM/dd/yyyy', 'UTC') : ''}</dd>
                </Col>
                <Col sm={3}>
                  <dt>Insurer</dt>
                </Col>
                <Col sm={9}>
                  {fetchingOrgs ? (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      size="sm"
                    />
                  ) : (
                    <dd>{buildCoveragePayer(orgData)}</dd>
                  )}
                </Col>
              </Row>
              {
                coverage.class && coverage.class.map((c: any, index: number) => (
                  <Row key={`class-${index}`}>
                    {['sequence', 'rxid', 'rxbin', 'rxgroup', 'rxpcn'].includes(getClass(c.type)) ? null : (
                      <>
                        <Col sm={3}>
                          <dt>{capitalizeFirstLetter(getClass(c.type))}</dt>
                        </Col>
                        <Col sm={9}>
                          {c.value || c.name}
                          <dd></dd>
                        </Col>
                      </>
                    )}
                  </Row>
                ))
              }
              <Row>
                {/* <Col sm={3}>
                  <dt>Payor</dt>
                </Col>
                <Col sm={9}>
                  <dd>{buildCoveragePayer(coverage)}</dd>
                </Col>
              </Row>
              {
                coverage.class && coverage.class.map((c: any) => (
                  <Row key={c.value}>
                    {['sequence', 'rxid', 'rxbin', 'rxgroup', 'rxpcn'].includes(c.type.coding[0].code) ? null : (
                      <>
                        <Col sm={3}>
                          <dt>{capitalizeFirstLetter(c.type.coding[0].code)}</dt>
                        </Col>
                        <Col sm={9}>
                          {c.value} {c.name}
                          <dd></dd>
                        </Col>
                      </>
                    )}
                  </Row>
                ))
              }
              <Row> */}
                {/* <Col sm={3}>
                  <dt>Resource URL</dt>
                </Col>
                <Col sm={9}>
                  <dd>{coverageData.link[0].url}</dd>
                </Col> */}
              </Row>
            </dl>
          ))
        ) :
          <Row>
            <Col className='text-center'>
              No Coverage found for this member.
            </Col>
          </Row>
      }
    </>
  )
}

export default Coverage