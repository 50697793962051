import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ForgotLoginModal from '../../components/Modals/ForgotLoginModal'
import LoginForm from './LoginForm'
import ChangePasswordForm from './ChangePasswordForm'
import EnterMfaForm from './EnterMfaForm'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { LoginSchema, ResetPasswordSchema } from '../../utils/validation'
import useQuery from '../../hooks/location/useQuery'
import useLoginUser from '../../hooks/authentication/useLoginUser'
import useUser from '../../hooks/authentication/useUser'
import useForgotLogin from '../../hooks/authentication/useForgotLogin'
import useChangeForgotPassword from '../../hooks/authentication/useChangeForgotPassword'
import useValidateMfaToken from '../../hooks/authentication/useValidateMfaToken'
import useAlert from '../../hooks/authentication/useAlert'
import '../../App.scss'
//---------------
import { FormattedMessage, IntlProvider } from 'react-intl'

import {
  companyMessage,
  companyNameFull,
  preferedName,
  dataPortalTitle,
  contactLink,
  contactEmail,
  contactPhone,
  companyDataDescription
} from '../../assets/i18n/i18n'
import { addCompanyPhoneMessage } from '../../utils/helpers'
import useValidateForgotPasswordCode from '../../hooks/authentication/useValidateForgotPasswordCode'
import { set } from 'date-fns'
//---------------

const Login: FC = () => {
  const {
    alert: loginAlert,
    handleCloseAlert: handleCloseLoginAlert,
    showAlert: showLoginAlert
  } = useAlert({ show: false, variant: 'danger', message: '' })

  const {
    alert: changePassAlert,
    handleCloseAlert: handleCloseChangePassAlert,
    showAlert: showChangePassAlert
  } = useAlert({ show: false, variant: 'danger', message: '' })

  const {
    alert: mfaAlert,
    handleCloseAlert: handleCloseMfaAlert,
    showAlert: showMfaAlert
  } = useAlert({ show: true, variant: 'info', message: 'Please check your email for a validation code' })

  const [showMfaForm, setShowMfaForm] = useState(false)
  const [showForgotLogin, setShowForgotLogin] = useState(false)
  const [modalType, setModalType] = useState('')
  const forgotLogin = useForgotLogin()
  const handleOpenForgotLogin = (type: string) => {
    setShowForgotLogin(true)
    setModalType(type)
  }

  const handleHideForgotLogin = () => {
    setShowForgotLogin(false)
  }

  const history = useHistory()
  const query = useQuery()
  const screen = query.get('screen')
  const code = query.get('code')

  const loginUser = useLoginUser()
  const { data: user, refetch, isLoading: userIsLoading, remove: removeUser } = useUser()

  const loginFormHandler = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      loginUser.mutate(values)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (loginUser.isSuccess) {
      showLoginAlert('success', 'Validation code sent...')
      setShowMfaForm(true)
    }
    if (loginUser.isError) {
      showLoginAlert('danger', addCompanyPhoneMessage(loginUser.error?.response.text))
    }
  }, [loginUser.isSuccess, loginUser.isError])

  const validateMfaToken = useValidateMfaToken()

  const mfaFormHandler = useFormik({
    initialValues: {
      mfaCode: '',
    },
    onSubmit: (values) => {
      showMfaAlert('info', 'Checking validation code...')
      validateMfaToken.mutate(values)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    if (validateMfaToken.isSuccess) {
      refetch()
    }
    if (validateMfaToken.isError) {
      showMfaAlert('danger', validateMfaToken.error?.response.text)
    }
  }, [validateMfaToken.isSuccess, validateMfaToken.isError])

  const changePassword = useChangeForgotPassword()
  const validateForgotPasswordCode = useValidateForgotPasswordCode()
  
  const changePasswordFormHandler = useFormik({
    initialValues: {
      password: '',
      passwordAgain: ''
    },
    onSubmit: (values) => {
      changePassword.mutate({
        password: values.password,
        resetToken: code,
        userId: validateForgotPasswordCode.data?.id
      })
    },
    validationSchema: ResetPasswordSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    if (changePassword.isSuccess) {
      showLoginAlert('success', 'Successfully changed your password.')
      history.push('/login')
    }
    if (changePassword.isError) {
      showChangePassAlert('danger', changePassword.error?.response.text)
    }
  }, [changePassword.isSuccess, changePassword.isError])

  useEffect(() => {
    if (user) {
      history.push('/')
    }
  }, [user])

  useEffect(() => {
    removeUser()
  }, [])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <main className='home'>
      <Container>
        <Row>
          <Col md={6} className='left'>
            <div>
              {screen ? (
                <ChangePasswordForm
                  formHandler={changePasswordFormHandler}
                  alertState={changePassAlert}
                  closeAlert={handleCloseChangePassAlert}
                  isLoading={changePassword.isLoading}
                  validateForgotPasswordCode={validateForgotPasswordCode}
                  code={code}
                />
              ) : (
                <div>
                {
                  showMfaForm ? (
                    <EnterMfaForm
                      formHandler={mfaFormHandler}
                      alertState={mfaAlert}
                      closeAlert={handleCloseMfaAlert}
                      isLoading={validateMfaToken.isLoading || userIsLoading}
                    />
                  ) : (
                    <LoginForm
                      formHandler={loginFormHandler}
                      alertState={loginAlert}
                      closeAlert={handleCloseLoginAlert}
                      openForgotModal={handleOpenForgotLogin}
                      isLoading={loginUser.isLoading}
                    />
                  )
                }
                </div>
              )}
            </div>
          </Col>
          <Col md={6} className='right'>
            <IntlProvider locale='en' messages={companyMessage}>

              <section>
                <h3 className='h4'>How Can I use the {preferedName} {dataPortalTitle}?</h3>
                <p>You can use the {dataPortalTitle} to view your {companyDataDescription}.</p>
                <p>You can also use your {dataPortalTitle} account to view your {companyDataDescription} inside of an authorized health history app of your choosing.</p>
                <p>Interested in viewing your data? <a href="/register">Register now</a></p>
                <p>Need more information? <a href="/help">Learn More</a></p>

              </section>
            </IntlProvider>
          </Col>
        </Row>
        <ForgotLoginModal
          show={showForgotLogin}
          onHide={handleHideForgotLogin}
          modalType={modalType}
          forgotLogin={forgotLogin}
        />
        <div className='lowerCallout'>
          <h3 className='h4'>
            <Link to='/register/requestor'>New User? - Click here to Register</Link>
          </h3>
        </div>
      </Container>
    </main>
  )
}

export default Login
