//! Edited

import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FormattedMessage, IntlProvider } from 'react-intl'

import {
  companyMessage,
  companyNameFull,
  companyDataDescription,
  dataPortalTitle,
  preferedName,
  contactLink,
  contactEmail,
  contactPhone,
  contactHours
} from '../../assets/i18n/i18n'

import '../../App.scss'

const Help: FC = () => {
  return (
    <main className='content'>
    
    <IntlProvider locale='en' messages={companyMessage}>
      <Container>

        <h2 className='headerText'>Help</h2>

        <div className="calloutContainer">
          <div>
            <h3>Contact {preferedName}</h3>

            <h4><a href={companyMessage.ContactLink}>{companyMessage.ContactLink}</a></h4>

            <h4>{companyMessage.ContactPhone}</h4>

          </div>
        </div>
        <h3>What is the {preferedName} {dataPortalTitle}?</h3>
        <p>You can use the {preferedName} {dataPortalTitle} to view your {companyDataDescription} online and also use your account to connect your data to commonly available health apps.</p>
 
        <h3>How does the {preferedName} {dataPortalTitle} work?</h3>
        <p>The {preferedName} {dataPortalTitle} provides a link between this FHIR data repository and external apps. The {dataPortalTitle} also allows the same data to be viewed online after logging in.
        </p>
        <p>For authentication, the {dataPortalTitle} uses OpenID Connect (OIDC), an open authentication protocol that works on top of the OAuth 2.0 framework, a single sign-on (SSO) method for authorized apps. </p>
        <p>
          Data is exchanged between health plans and external applications using
          Health Level 7&reg; (HL7) Fast Healthcare Interoperability
          Resources&reg; (FHIR) Release 4.0.1 as the foundational standard to
          support data exchange via secure application programming interfaces
          (APIs).
        </p>

        <h3>Why was the {preferedName} {dataPortalTitle} set up?</h3>
        <p>
          The {preferedName} {dataPortalTitle} and FHIR repository was created to meet the requirements of the Centers for
          Medicare & Medicaid Services Interoperability and Patient Access final
          rule (CMS-9115-F). This rule is meant to give patients better access to
          their health information, leading to better care and improved outcomes.
          For more information on Interoperability and Patient Access visit:{' '}
          <a
            href="https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet"
            target="_blank"
            rel="noreferrer"
          >
            https://www.cms.gov/newsroom/fact-sheets/interoperability-and-patient-access-fact-sheet
          </a>
        </p>

        <h3>Is the {preferedName} {dataPortalTitle} Secure?</h3>
        <p>
          The {preferedName} {dataPortalTitle} is a HIPAA-compliant secure application and data exchange.
        </p>
        <p>
          It is important to know that once you authorize an external application, that application has its own level of security and may not be HIPAA-compliant.
          Your should review the terms of use of any external app prior to granting access. Only grant access to trusted apps.&nbsp;
          {preferedName} also recommends limiting the number of external apps that are authorized to limit potential exposure.
        </p>

        <h3>What are my rights under the Health Insurance Portability and Accountability Act (HIPAA) and who must follow HIPAA?</h3>
        <p>The U.S. Department of Health and Human Services (HHS) Office for Civil Rights (OCR) enforces the HIPAA Privacy, Security, and Breach Notification Rules, and the Member Safety Act and Rule. 
          You can find more information about member rights under HIPAA and who is obligated to follow HIPAA, here: <a href="https://www.hhs.gov/hipaa/for-individuals/faq/index.html" target="_blank" rel="noreferrer">https://www.hhs.gov/hipaa/for-individuals/faq/index.html</a>
        </p>
 
        <h3>Are apps I choose to use with my {preferedName} data covered by HIPAA?</h3>
        <p>Most third-party apps will not be covered by HIPAA. 
          Most third-party apps will instead fall under the jurisdiction of the Federal Trade Commission (FTC) and the protections provided by the FTC Act. 
          The FTC Act, among other things, protects against deceptive acts (e.g., if an app shares personal data without permission, despite having a privacy policy that says it will not do so). 
          The FTC provides general information about keeping yourself and your data safe online here: <a href="https://consumer.ftc.gov/identity-theft-and-online-security/online-privacy-and-security" target="_blank" rel="noreferrer">https://consumer.ftc.gov/identity-theft-and-online-security/online-privacy-and-security</a>
        </p>

      <h3>What should you do if you think your data has been breached or an app has used your data inappropriately?</h3>
      <p>You can file a complaint with OCR under HIPAA: <a href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html" target="_blank" rel="noreferrer">https://www.hhs.gov/hipaa/filing-a-complaint/index.html</a></p>
      <p>You can file a complaint with OCR using the OCR complaint portal: <a href="https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf" target="_blank" rel="noreferrer">https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf</a></p>
      <p>You can file a complaint with the FTC using the FTC complaint assistant: <a href="https://reportfraud.ftc.gov/" target="_blank" rel="noreferrer">https://reportfraud.ftc.gov/</a></p>

        <h3>What is ConnectID?</h3>

<p>Connected ID is a FHIR-Based member portal product from TESCHGlobal.</p>


      <h3>What is HealthLX?</h3>

<p>HealthLX (Healthcare Language Exchange) is a health tech company focused on solving interoperability and data exchange for payers, providers, and HIT vendors.</p>

      </Container >
    </IntlProvider>
    </main>
  )
}

export default Help
