import React, { FC, useState, useEffect } from 'react'
import { appConfig } from '../../../assets/customizations/config'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import Eobs from '../../../components/FhirData/Eobs'
import Immunizations from '../../../components/FhirData/Immunizations'
import MemberProfile from '../../../components/FhirData/MemberProfile'
import Observations from '../../../components/FhirData/Observations'
import Coverage from '../../../components/FhirData/Coverage'
import Conditions from '../../../components/FhirData/Conditions'
import Allergies from '../../../components/FhirData/Allergies'
import Medications from '../../../components/FhirData/Medications'
import Procedure from '../../../components/FhirData/Procedure'
import isAuthenticated from '../../../routes/PrivateRoute'

import { useRole } from '../../../hooks/authentication/useRole'
import useUser from '../../../hooks/authentication/useUser'
import { useGlobalState } from '../../../hooks/store/useGlobalState'
import useFhirResource from '../../../hooks/admin/useFhirResource'
import { getUrlParameter, scrollToTop } from '../../../utils/helpers'

import '../../../App.scss'

interface RouteParams {
  page: string | undefined;
  detailId: string | undefined;
}

const Member: FC = () => {
  const fhirId = useGlobalState((state) => state.fhirId)
  const [key, setKey] = useState('Patient')
  const { page, detailId } = useParams<RouteParams>()
  const history = useHistory()


  const { data: patientData, refetch: getPatient, isLoading: patientLoading, isStale: patientIsStale } = useFhirResource(fhirId, 'Patient')
  const { data: coverageData, refetch: getPatientCoverages, isStale: coveragesIsStale, isFetching: fetchingCoverage, isLoading: loadingCoverages } = useFhirResource(fhirId, 'Coverage', 25)
  const { data: conditionData, refetch: getPatientConditions, isStale: conditionsIsStale, isFetching: fetchingConditions } = useFhirResource(fhirId, 'Condition', 100)

  const { data: immunizationData, refetch: getImmunizations, isStale: immunizationsIsStale, isFetching: fetchingImmunizations } = useFhirResource(fhirId, 'Immunization', 25)
  const { data: observationData, refetch: getObservations, isStale: observationsIsStale, isFetching: fetchingObservations } = useFhirResource(fhirId, 'Observation', 25)
  const { data: allergyData, refetch: getPatientAllergies, isStale: allergiesIsStale, isFetching: fetchingAllergies } = useFhirResource(fhirId, 'AllergyIntolerance', 25)
  const { data: medications, refetch: getMedications, isStale: medicationsIsStale, isFetching: fetchingMedications } = useFhirResource(fhirId, 'MedicationRequest', 25)
  const { data: procedures, refetch: getProcedures, isStale: proceduresIsStale, isFetching: fetchingProcedures } = useFhirResource(fhirId, 'Procedure', 25)

  const [eobPageState, setEobPageState] = useState({
    getEobPages: '',
    getEobPagesOffset: '',
    eobPageId: ''
  })

  const { data: eobData, refetch: getPatientEobs, isStale: eobsIsStale, isFetching: fetchingEobs } = useFhirResource(fhirId, 'ExplanationOfBenefit', 15, eobPageState.getEobPages, eobPageState.getEobPagesOffset, eobPageState.eobPageId)

  const handleChangeEobPage = (url: string) => {
    setEobPageState({
      getEobPages: getUrlParameter('_getpages', url),
      getEobPagesOffset: getUrlParameter('_getpagesoffset', url),
      eobPageId: getUrlParameter('_pageId', url)
    })
  }

  const { role } = useRole()
  const { data: user } = useUser()

  useEffect(() => {
    if (key === 'Patient' && (patientIsStale || coveragesIsStale)) {
      getPatient()
      getPatientCoverages()
    }

    if (key === 'Coverage' && coveragesIsStale) {
      getPatientCoverages()
    }
    if (key === 'AllergyIntolerance' && allergiesIsStale) {
      getPatientAllergies()
    }

    if (key === 'Conditions' && conditionsIsStale) {
      getPatientConditions()
    }

    if (key === 'EOBs' && eobsIsStale) {
      getPatientEobs()
    }

    if (key === 'Immunizations' && immunizationsIsStale) {
      getImmunizations()
    }

    if (key === 'Observations' && observationsIsStale) {
      getObservations()
    }

    if (key === 'Medications' && medicationsIsStale) {
      getMedications()
    }

    if (key === 'Procedures' && proceduresIsStale) {
      getProcedures()
    }

  }, [key, patientIsStale, eobsIsStale, coveragesIsStale, conditionsIsStale, detailId, allergiesIsStale, proceduresIsStale])

  useEffect(() => {
    setKey(page || 'Patient')
    scrollToTop()
  }, [page])

  useEffect(() => {
    if (detailId) {
      scrollToTop()
    }
  }, [detailId])

  const handleChangeKey = (k: string | null) => {
    const key = k || ''
    setKey(key)

    if (key) {
      const path = key === 'Patient' ? '/patient' : `/patient/${key}`
      history.push(path)
    }

    scrollToTop()
  }

  useEffect(() => {
    if (role === 'member') {
      if (fhirId !== user.memberId) {
        history.replace('/404')
      }
    }
  }, [fhirId, role])


  return (
    <main className='fhirData'>
      <Container>
        {/* <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
          Admin Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/members' }}>
          Members
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/patient/${memberId}` }} active={key === 'Patient'}>
          {patientData ? buildPatientName(patientData.name) : ''}
        </Breadcrumb.Item>
        {
          key !== 'Patient' && (
            <>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/patient/${memberId}/${page}` }} as='li' active={key !== 'Patient' && !detailId}>
                {key}
              </Breadcrumb.Item>
              {
                detailId && (
                  <Breadcrumb.Item active>{trimmedBreadCrumbID}</Breadcrumb.Item>
                )
              }
            </>
          )
        }
      </Breadcrumb> */}
        <Tab.Container activeKey={key} onSelect={handleChangeKey} transition={false}>
          <h2 className='headerText'>Member FHIR Data</h2>
          <Row>
            <Col sm={2}>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item className='patient-pill'>
                  <Nav.Link style={{ color: 'white' }} eventKey='Patient'>Member Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item className='patient-pill'>
                  <Nav.Link eventKey='Coverage'>Coverage</Nav.Link>
                </Nav.Item>
                <Nav.Item className='patient-pill'>
                  <Nav.Link eventKey='EOBs'>EOBs</Nav.Link>
                </Nav.Item>
                {
                  appConfig.show_allergy_intolerance && (
                    <Nav.Item className='patient-pill'>
                      <Nav.Link eventKey='AllergyIntolerance'>Allergies</Nav.Link>
                    </Nav.Item>
                  )
                }
                {
                  appConfig.show_condition && (
                    <Nav.Item className='patient-pill'>
                      <Nav.Link eventKey='Conditions'>Conditions</Nav.Link>
                    </Nav.Item>
                  )
                }
                {
                  appConfig.show_immunization && (
                    <Nav.Item className='patient-pill'>
                      <Nav.Link eventKey='Immunizations'>Immunizations</Nav.Link>
                    </Nav.Item>
                  )
                }
                {
                  appConfig.show_medication && (
                    <Nav.Item className='patient-pill'>
                      <Nav.Link eventKey='Medications'>Medications</Nav.Link>
                    </Nav.Item>
                  )
                }
                {
                  appConfig.use_observation_resource && (
                    <Nav.Item className='patient-pill'>
                      <Nav.Link eventKey='Observations'>Observations</Nav.Link>
                    </Nav.Item>
                  )
                }
                {
                  appConfig.show_procedure && (
                    <Nav.Item className='patient-pill'>
                      <Nav.Link eventKey='Procedures'>Procedures</Nav.Link>
                    </Nav.Item>
                  )
                }
              </Nav>
            </Col>
            <Col sm={10} className='mt-sm-0 mt-3'>
              <Tab.Content>
                <Tab.Pane eventKey='Patient'>
                  <MemberProfile
                    patientData={patientData}
                    loading={patientLoading || loadingCoverages}
                    coverageData={coverageData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='AllergyIntolerance'>
                  <Allergies
                    patientData={patientData}
                    allergyData={allergyData}
                    isFetching={fetchingAllergies}
                    allergyDetailId={detailId}
                    page={page}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='Coverage'>
                  <Coverage
                    patientData={patientData}
                    coverageData={coverageData}
                    isFetching={fetchingCoverage}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='Conditions'>
                  <Conditions
                    conditionData={conditionData}
                    isFetching={fetchingConditions}
                    patientData={patientData}
                    conditionDetailId={detailId}
                    page={page}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='EOBs'>
                  <Eobs
                    patientData={patientData}
                    eobData={eobData}
                    eobDetailId={detailId}
                    page={page}
                    isFetching={fetchingEobs}
                    handleChangePage={handleChangeEobPage}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='Immunizations'>
                  <Immunizations
                    patientData={patientData}
                    immunizationData={immunizationData}
                    immunizationDetailId={detailId}
                    page={page}
                    isFetching={fetchingImmunizations}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='Observations'>
                  <Observations
                    patientData={patientData}
                    observationData={observationData}
                    observationDetailId={detailId}
                    page={page}
                    isFetching={fetchingObservations}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='Medications'>
                  <Medications
                    patientData={patientData}
                    page={page}
                    isFetching={fetchingMedications}
                    medicationData={medications}
                    medicationDetailId={detailId}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='Procedures'>
                  <Procedure
                    patientData={patientData}
                    page={page}
                    isFetching={fetchingProcedures}
                    procedureData={procedures}
                    procedureDetailId={detailId}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </main>
  )
}

export default isAuthenticated(Member)