import React, { FC } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { appConfig } from '../../../assets/customizations/config'

import '../../../App.scss'
import { RequestorSchema } from '../../../utils/validation'
import { Link } from 'react-router-dom'

import {
  dataPortalTitle,
} from '../../../assets/i18n/i18n'

interface Props {
  initialValues: any;
  onSubmit: (values: any) => void;
}

const Requestor = ({ initialValues, onSubmit }: Props) => {

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RequestorSchema,
    onSubmit: (values) => {
      onSubmit(values)
    },
    enableReinitialize: true
  })

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className='formContainer'>
      <Row>
        <Form.Group as={Col} xs={12}>
          <Form.Check
            type='radio'
            label={<label>I am registering for myself.</label>}
            checked={formik.values.type === 'self'}
            onChange={(event) => {
              setTimeout(() => formik.setFieldValue('type', 'self'))
              setTimeout(() => formik.setFieldTouched('type', true))
            }}
            isInvalid={!!formik.errors.type && !!formik.touched.type}
          />
          <Form.Check
            type='radio'
            label={<label>I am a legal guardian/poa registering on behalf of a member.</label>}
            checked={formik.values.type === 'proxy'}
            onChange={(event) => {
              setTimeout(() => formik.setFieldValue('type', 'proxy'))
              setTimeout(() => formik.setFieldTouched('type', true))
            }}
            isInvalid={!!formik.errors.type && !!formik.touched.type}
          />
          {formik.errors.type && formik.touched.type && (
            <Form.Text className='text-danger mb-3'>
              {formik.errors.type}
            </Form.Text>
          )}
        </Form.Group>
        {
          formik.values.type === 'proxy' && (
            <>
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Your First Name:</Form.Label>
                <Form.Control
                  type='text'
                  name='firstName'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6} sm={12}>
                <Form.Label>Your Last Name:</Form.Label>
                <Form.Control
                  type='text'
                  name='lastName'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )
        }
        <Col xs={12}>
          <hr />
        </Col>
        <Col xs={12}>
          <Form.Check
            type='checkbox'
            label={<label>I agree to the <Link to='/terms'>Terms and Conditions</Link> of the {dataPortalTitle}.</label>}
            checked={formik.values.acceptedTerms}
            name='acceptedTerms'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors.acceptedTerms && !!formik.touched.acceptedTerms}
          />
          {formik.errors.acceptedTerms && formik.touched.acceptedTerms && (
            <Form.Text className='text-danger mb-3'>
              {formik.errors.acceptedTerms}
            </Form.Text>
          )}
        </Col>
      </Row>
      <Button className='custom' type='submit'>
        Next &gt;&gt;
      </Button>
    </Form>
  )
}

export default Requestor