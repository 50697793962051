import React, { FC } from 'react'
import AllergyList from './AllergyList'
import AllergyDetail from './AllergyDetail'
import { buildPatientName } from '../../../utils/fhirUtils/patient'

interface Props {
    allergyData: any;
    patientData: any;
    allergyDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
}




const Allergies: FC<Props> = ({ allergyData, patientData, allergyDetailId, page, isFetching }: Props) => {

    return (
        <div>
            <h3>Allergies</h3>
            <h4>{patientData ? buildPatientName(patientData.name) : ''}</h4>
            {
                allergyDetailId && page === 'AllergyIntolerance' ? (
                    <AllergyDetail allergyData={allergyData} isFetching={isFetching} allergyDetailId={allergyDetailId} />
                ) : <AllergyList allergyData={allergyData} patientData={patientData} isFetching={isFetching} />
            }
        </div>
    )
}

export default Allergies